.is-small-text {
  font-size: 0.875em;
}

.is-regular-text {
  font-size: 1em;
}

.is-large-text {
  font-size: 2.25em;
}

.is-larger-text {
  font-size: 3em;
}

.has-drop-cap:not(:focus):first-letter {
  float: left;
  font-size: 8.4em;
  font-style: normal;
  font-weight: 100;
  line-height: 0.68;
  margin: 0.05em 0.1em 0 0;
  text-transform: uppercase;
}

body.rtl .has-drop-cap:not(:focus):first-letter {
  float: none;
  margin-left: 0.1em;
}

p.has-drop-cap.has-background {
  overflow: hidden;
}

p.has-background {
  padding: 1.25em 2.375em;
}

:where(p.has-text-color:not(.has-link-color)) a {
  color: inherit;
}

p.has-text-align-left[style*="writing-mode:vertical-lr"],
p.has-text-align-right[style*="writing-mode:vertical-rl"] {
  rotate: 180deg;
}
h1.has-background,
h2.has-background,
h3.has-background,
h4.has-background,
h5.has-background,
h6.has-background {
  padding: 1.25em 2.375em;
}
h1.has-text-align-left[style*="writing-mode"]:where([style*="vertical-lr"]),
h1.has-text-align-right[style*="writing-mode"]:where([style*="vertical-rl"]),
h2.has-text-align-left[style*="writing-mode"]:where([style*="vertical-lr"]),
h2.has-text-align-right[style*="writing-mode"]:where([style*="vertical-rl"]),
h3.has-text-align-left[style*="writing-mode"]:where([style*="vertical-lr"]),
h3.has-text-align-right[style*="writing-mode"]:where([style*="vertical-rl"]),
h4.has-text-align-left[style*="writing-mode"]:where([style*="vertical-lr"]),
h4.has-text-align-right[style*="writing-mode"]:where([style*="vertical-rl"]),
h5.has-text-align-left[style*="writing-mode"]:where([style*="vertical-lr"]),
h5.has-text-align-right[style*="writing-mode"]:where([style*="vertical-rl"]),
h6.has-text-align-left[style*="writing-mode"]:where([style*="vertical-lr"]),
h6.has-text-align-right[style*="writing-mode"]:where([style*="vertical-rl"]) {
  rotate: 180deg;
}
ol,
ul {
  box-sizing: border-box;
}
ol.has-background,
ul.has-background {
  padding: 1.25em 2.375em;
}
.wp-block-quote {
  box-sizing: border-box;
  overflow-wrap: break-word;
}
.wp-block-quote.is-large:where(:not(.is-style-plain)),
.wp-block-quote.is-style-large:where(:not(.is-style-plain)) {
  margin-bottom: 1em;
  padding: 0 1em;
}
.wp-block-quote.is-large:where(:not(.is-style-plain)) p,
.wp-block-quote.is-style-large:where(:not(.is-style-plain)) p {
  font-size: 1.5em;
  font-style: italic;
  line-height: 1.6;
}
.wp-block-quote.is-large:where(:not(.is-style-plain)) cite,
.wp-block-quote.is-large:where(:not(.is-style-plain)) footer,
.wp-block-quote.is-style-large:where(:not(.is-style-plain)) cite,
.wp-block-quote.is-style-large:where(:not(.is-style-plain)) footer {
  font-size: 1.125em;
  text-align: right;
}
.wp-block-code {
  box-sizing: border-box;
}
.wp-block-code code {
  display: block;
  font-family: inherit;
  overflow-wrap: break-word;
  white-space: pre-wrap;
}
.wp-block-details {
  box-sizing: border-box;
  overflow: hidden;
}

.wp-block-details summary {
  cursor: pointer;
}
.wp-block-preformatted {
  box-sizing: border-box;
  white-space: pre-wrap;
}

:where(.wp-block-preformatted.has-background) {
  padding: 1.25em 2.375em;
}
.wp-block-pullquote {
  box-sizing: border-box;
  overflow-wrap: break-word;
  padding: 3em 0;
  text-align: center;
}
.wp-block-pullquote blockquote,
.wp-block-pullquote cite,
.wp-block-pullquote p {
  color: inherit;
}
.wp-block-pullquote.alignleft,
.wp-block-pullquote.alignright {
  max-width: 420px;
}
.wp-block-pullquote cite,
.wp-block-pullquote footer {
  position: relative;
}
.wp-block-pullquote .has-text-color a {
  color: inherit;
}

:where(.wp-block-pullquote) {
  margin: 0 0 1em;
}

.wp-block-pullquote.has-text-align-left blockquote {
  text-align: left;
}

.wp-block-pullquote.has-text-align-right blockquote {
  text-align: right;
}

.wp-block-pullquote.is-style-solid-color {
  border: none;
}
.wp-block-pullquote.is-style-solid-color blockquote {
  margin-left: auto;
  margin-right: auto;
  max-width: 60%;
}
.wp-block-pullquote.is-style-solid-color blockquote p {
  font-size: 2em;
  margin-bottom: 0;
  margin-top: 0;
}
.wp-block-pullquote.is-style-solid-color blockquote cite {
  font-style: normal;
  text-transform: none;
}

.wp-block-pullquote cite {
  color: inherit;
}
.wp-block-table {
  overflow-x: auto;
}
.wp-block-table table {
  border-collapse: collapse;
  width: 100%;
}
.wp-block-table thead {
  border-bottom: 3px solid;
}
.wp-block-table tfoot {
  border-top: 3px solid;
}
.wp-block-table td,
.wp-block-table th {
  border: 1px solid;
  padding: 0.5em;
}
.wp-block-table .has-fixed-layout {
  table-layout: fixed;
  width: 100%;
}
.wp-block-table .has-fixed-layout td,
.wp-block-table .has-fixed-layout th {
  word-break: break-word;
}
.wp-block-table.aligncenter,
.wp-block-table.alignleft,
.wp-block-table.alignright {
  display: table;
  width: auto;
}
.wp-block-table.aligncenter td,
.wp-block-table.aligncenter th,
.wp-block-table.alignleft td,
.wp-block-table.alignleft th,
.wp-block-table.alignright td,
.wp-block-table.alignright th {
  word-break: break-word;
}
.wp-block-table .has-subtle-light-gray-background-color {
  background-color: #f3f4f5;
}
.wp-block-table .has-subtle-pale-green-background-color {
  background-color: #e9fbe5;
}
.wp-block-table .has-subtle-pale-blue-background-color {
  background-color: #e7f5fe;
}
.wp-block-table .has-subtle-pale-pink-background-color {
  background-color: #fcf0ef;
}
.wp-block-table.is-style-stripes {
  background-color: transparent;
  border-bottom: 1px solid #f0f0f0;
  border-collapse: inherit;
  border-spacing: 0;
}
.wp-block-table.is-style-stripes tbody tr:nth-child(odd) {
  background-color: #f0f0f0;
}
.wp-block-table.is-style-stripes.has-subtle-light-gray-background-color
  tbody
  tr:nth-child(odd) {
  background-color: #f3f4f5;
}
.wp-block-table.is-style-stripes.has-subtle-pale-green-background-color
  tbody
  tr:nth-child(odd) {
  background-color: #e9fbe5;
}
.wp-block-table.is-style-stripes.has-subtle-pale-blue-background-color
  tbody
  tr:nth-child(odd) {
  background-color: #e7f5fe;
}
.wp-block-table.is-style-stripes.has-subtle-pale-pink-background-color
  tbody
  tr:nth-child(odd) {
  background-color: #fcf0ef;
}
.wp-block-table.is-style-stripes td,
.wp-block-table.is-style-stripes th {
  border-color: transparent;
}
.wp-block-table .has-border-color td,
.wp-block-table .has-border-color th,
.wp-block-table .has-border-color tr,
.wp-block-table .has-border-color > * {
  border-color: inherit;
}
.wp-block-table table[style*="border-top-color"] tr:first-child,
.wp-block-table table[style*="border-top-color"] tr:first-child td,
.wp-block-table table[style*="border-top-color"] tr:first-child th,
.wp-block-table table[style*="border-top-color"] > *,
.wp-block-table table[style*="border-top-color"] > * td,
.wp-block-table table[style*="border-top-color"] > * th {
  border-top-color: inherit;
}
.wp-block-table table[style*="border-top-color"] tr:not(:first-child) {
  border-top-color: currentColor;
}
.wp-block-table table[style*="border-right-color"] td:last-child,
.wp-block-table table[style*="border-right-color"] th,
.wp-block-table table[style*="border-right-color"] tr,
.wp-block-table table[style*="border-right-color"] > * {
  border-right-color: inherit;
}
.wp-block-table table[style*="border-bottom-color"] tr:last-child,
.wp-block-table table[style*="border-bottom-color"] tr:last-child td,
.wp-block-table table[style*="border-bottom-color"] tr:last-child th,
.wp-block-table table[style*="border-bottom-color"] > *,
.wp-block-table table[style*="border-bottom-color"] > * td,
.wp-block-table table[style*="border-bottom-color"] > * th {
  border-bottom-color: inherit;
}
.wp-block-table table[style*="border-bottom-color"] tr:not(:last-child) {
  border-bottom-color: currentColor;
}
.wp-block-table table[style*="border-left-color"] td:first-child,
.wp-block-table table[style*="border-left-color"] th,
.wp-block-table table[style*="border-left-color"] tr,
.wp-block-table table[style*="border-left-color"] > * {
  border-left-color: inherit;
}
.wp-block-table table[style*="border-style"] td,
.wp-block-table table[style*="border-style"] th,
.wp-block-table table[style*="border-style"] tr,
.wp-block-table table[style*="border-style"] > * {
  border-style: inherit;
}
.wp-block-table table[style*="border-width"] td,
.wp-block-table table[style*="border-width"] th,
.wp-block-table table[style*="border-width"] tr,
.wp-block-table table[style*="border-width"] > * {
  border-style: inherit;
  border-width: inherit;
}
.wp-block-image img {
  box-sizing: border-box;
  height: auto;
  max-width: 100%;
  vertical-align: bottom;
}
.wp-block-image[style*="border-radius"] img,
.wp-block-image[style*="border-radius"] > a {
  border-radius: inherit;
}
.wp-block-image.has-custom-border img {
  box-sizing: border-box;
}
.wp-block-image.aligncenter {
  text-align: center;
}
.wp-block-image .aligncenter {
  text-align: center;
}
.wp-block-image.alignfull img,
.wp-block-image.alignwide img {
  height: auto;
  width: 100%;
}
.wp-block-image .aligncenter,
.wp-block-image .alignleft,
.wp-block-image .alignright,
.wp-block-image.aligncenter,
.wp-block-image.alignleft,
.wp-block-image.alignright {
  display: table;
  width: 100%;
}
.wp-block-image .aligncenter > figcaption,
.wp-block-image .alignleft > figcaption,
.wp-block-image .alignright > figcaption,
.wp-block-image.aligncenter > figcaption,
.wp-block-image.alignleft > figcaption,
.wp-block-image.alignright > figcaption {
  caption-side: bottom;
  display: table-caption;
}
.wp-block-image .alignleft {
  float: left;
  margin: 0.5em 1em 0.5em 0;
}
.wp-block-image .alignright {
  float: right;
  margin: 0.5em 0 0.5em 1em;
}
.wp-block-image .aligncenter {
  margin-left: auto;
  margin-right: auto;
}
.wp-block-image figcaption {
  margin-bottom: 1em;
  margin-top: 0.5em;
}
.wp-block-image .is-style-rounded img,
.wp-block-image.is-style-circle-mask img,
.wp-block-image.is-style-rounded img {
  border-radius: 9999px;
}
@supports ((-webkit-mask-image: none) or (mask-image: none)) or
  (-webkit-mask-image: none) {
  .wp-block-image.is-style-circle-mask img {
    border-radius: 0;
    -webkit-mask-image: url('data:image/svg+xml;utf8,<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg"><circle cx="50" cy="50" r="50"/></svg>');
    mask-image: url('data:image/svg+xml;utf8,<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg"><circle cx="50" cy="50" r="50"/></svg>');
    mask-mode: alpha;
    -webkit-mask-position: center;
    mask-position: center;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-size: contain;
    mask-size: contain;
  }
}
.wp-block-image :where(.has-border-color) {
  border-style: solid;
}
.wp-block-image :where([style*="border-top-color"]) {
  border-top-style: solid;
}
.wp-block-image :where([style*="border-right-color"]) {
  border-right-style: solid;
}
.wp-block-image :where([style*="border-bottom-color"]) {
  border-bottom-style: solid;
}
.wp-block-image :where([style*="border-left-color"]) {
  border-left-style: solid;
}
.wp-block-image :where([style*="border-width"]) {
  border-style: solid;
}
.wp-block-image :where([style*="border-top-width"]) {
  border-top-style: solid;
}
.wp-block-image :where([style*="border-right-width"]) {
  border-right-style: solid;
}
.wp-block-image :where([style*="border-bottom-width"]) {
  border-bottom-style: solid;
}
.wp-block-image :where([style*="border-left-width"]) {
  border-left-style: solid;
}

.wp-block-image figure {
  margin: 0;
}

.wp-lightbox-container {
  display: flex;
  flex-direction: column;
  position: relative;
}
.wp-lightbox-container img {
  cursor: zoom-in;
}
.wp-lightbox-container img:hover + button {
  opacity: 1;
}
.wp-lightbox-container button {
  align-items: center;
  -webkit-backdrop-filter: blur(16px) saturate(180%);
  backdrop-filter: blur(16px) saturate(180%);
  background-color: rgba(90, 90, 90, 0.25);
  border: none;
  border-radius: 4px;
  cursor: zoom-in;
  display: flex;
  height: 20px;
  justify-content: center;
  opacity: 0;
  padding: 0;
  position: absolute;
  right: 16px;
  text-align: center;
  top: 16px;
  transition: opacity 0.2s ease;
  width: 20px;
  z-index: 100;
}
.wp-lightbox-container button:focus-visible {
  outline: 3px auto rgba(90, 90, 90, 0.25);
  outline: 3px auto -webkit-focus-ring-color;
  outline-offset: 3px;
}
.wp-lightbox-container button:hover {
  cursor: pointer;
  opacity: 1;
}
.wp-lightbox-container button:focus {
  opacity: 1;
}
.wp-lightbox-container button:focus,
.wp-lightbox-container button:hover,
.wp-lightbox-container button:not(:hover):not(:active):not(.has-background) {
  background-color: rgba(90, 90, 90, 0.25);
  border: none;
}

.wp-lightbox-overlay {
  box-sizing: border-box;
  cursor: zoom-out;
  height: 100vh;
  left: 0;
  overflow: hidden;
  position: fixed;
  top: 0;
  visibility: hidden;
  width: 100vw;
  z-index: 100000;
}
.wp-lightbox-overlay .close-button {
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  min-height: 40px;
  min-width: 40px;
  padding: 0;
  position: absolute;
  right: calc(env(safe-area-inset-right) + 16px);
  top: calc(env(safe-area-inset-top) + 16px);
  z-index: 5000000;
}
.wp-lightbox-overlay .close-button:focus,
.wp-lightbox-overlay .close-button:hover,
.wp-lightbox-overlay
  .close-button:not(:hover):not(:active):not(.has-background) {
  background: none;
  border: none;
}
.wp-lightbox-overlay .lightbox-image-container {
  height: var(--wp--lightbox-container-height);
  left: 50%;
  overflow: hidden;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  transform-origin: top left;
  width: var(--wp--lightbox-container-width);
  z-index: 9999999999;
}
.wp-lightbox-overlay .wp-block-image {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  height: 100%;
  justify-content: center;
  margin: 0;
  position: relative;
  transform-origin: 0 0;
  width: 100%;
  z-index: 3000000;
}
.wp-lightbox-overlay .wp-block-image img {
  height: var(--wp--lightbox-image-height);
  min-height: var(--wp--lightbox-image-height);
  min-width: var(--wp--lightbox-image-width);
  width: var(--wp--lightbox-image-width);
}
.wp-lightbox-overlay .wp-block-image figcaption {
  display: none;
}
.wp-lightbox-overlay button {
  background: none;
  border: none;
}
.wp-lightbox-overlay .scrim {
  background-color: #fff;
  height: 100%;
  opacity: 0.9;
  position: absolute;
  width: 100%;
  z-index: 2000000;
}
.wp-lightbox-overlay.active {
  animation: turn-on-visibility 0.25s both;
  visibility: visible;
}
.wp-lightbox-overlay.active img {
  animation: turn-on-visibility 0.35s both;
}
.wp-lightbox-overlay.hideanimationenabled:not(.active) {
  animation: turn-off-visibility 0.35s both;
}
.wp-lightbox-overlay.hideanimationenabled:not(.active) img {
  animation: turn-off-visibility 0.25s both;
}
@media (prefers-reduced-motion: no-preference) {
  .wp-lightbox-overlay.zoom.active {
    animation: none;
    opacity: 1;
    visibility: visible;
  }
  .wp-lightbox-overlay.zoom.active .lightbox-image-container {
    animation: lightbox-zoom-in 0.4s;
  }
  .wp-lightbox-overlay.zoom.active .lightbox-image-container img {
    animation: none;
  }
  .wp-lightbox-overlay.zoom.active .scrim {
    animation: turn-on-visibility 0.4s forwards;
  }
  .wp-lightbox-overlay.zoom.hideanimationenabled:not(.active) {
    animation: none;
  }
  .wp-lightbox-overlay.zoom.hideanimationenabled:not(.active)
    .lightbox-image-container {
    animation: lightbox-zoom-out 0.4s;
  }
  .wp-lightbox-overlay.zoom.hideanimationenabled:not(.active)
    .lightbox-image-container
    img {
    animation: none;
  }
  .wp-lightbox-overlay.zoom.hideanimationenabled:not(.active) .scrim {
    animation: turn-off-visibility 0.4s forwards;
  }
}

@keyframes turn-on-visibility {
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes turn-off-visibility {
  0% {
    opacity: 1;
    visibility: visible;
  }
  99% {
    opacity: 0;
    visibility: visible;
  }
  to {
    opacity: 0;
    visibility: hidden;
  }
}
@keyframes lightbox-zoom-in {
  0% {
    transform: translate(
        calc(-50vw + var(--wp--lightbox-initial-left-position)),
        calc(-50vh + var(--wp--lightbox-initial-top-position))
      )
      scale(var(--wp--lightbox-scale));
  }
  to {
    transform: translate(-50%, -50%) scale(1);
  }
}
@keyframes lightbox-zoom-out {
  0% {
    transform: translate(-50%, -50%) scale(1);
    visibility: visible;
  }
  99% {
    visibility: visible;
  }
  to {
    transform: translate(
        calc(-50vw + var(--wp--lightbox-initial-left-position)),
        calc(-50vh + var(--wp--lightbox-initial-top-position))
      )
      scale(var(--wp--lightbox-scale));
    visibility: hidden;
  }
}
.wp-block-video {
  box-sizing: border-box;
}
.wp-block-video video {
  vertical-align: middle;
  width: 100%;
}
@supports (position: sticky) {
  .wp-block-video [poster] {
    object-fit: cover;
  }
}
.wp-block-video.aligncenter {
  text-align: center;
}
.wp-block-video figcaption {
  margin-bottom: 1em;
  margin-top: 0.5em;
}
.wp-block-media-text {
  box-sizing: border-box;
  direction: ltr;
  display: grid;
  grid-template-columns: 50% 1fr;
  grid-template-rows: auto;
}
.wp-block-media-text.has-media-on-the-right {
  grid-template-columns: 1fr 50%;
}

.wp-block-media-text.is-vertically-aligned-top .wp-block-media-text__content,
.wp-block-media-text.is-vertically-aligned-top .wp-block-media-text__media {
  align-self: start;
}

.wp-block-media-text .wp-block-media-text__content,
.wp-block-media-text .wp-block-media-text__media,
.wp-block-media-text.is-vertically-aligned-center .wp-block-media-text__content,
.wp-block-media-text.is-vertically-aligned-center .wp-block-media-text__media {
  align-self: center;
}

.wp-block-media-text.is-vertically-aligned-bottom .wp-block-media-text__content,
.wp-block-media-text.is-vertically-aligned-bottom .wp-block-media-text__media {
  align-self: end;
}

.wp-block-media-text .wp-block-media-text__media {
  grid-column: 1;
  grid-row: 1;
  margin: 0;
}

.wp-block-media-text .wp-block-media-text__content {
  direction: ltr;
  grid-column: 2;
  grid-row: 1;
  padding: 0 8%;
  word-break: break-word;
}

.wp-block-media-text.has-media-on-the-right .wp-block-media-text__media {
  grid-column: 2;
  grid-row: 1;
}

.wp-block-media-text.has-media-on-the-right .wp-block-media-text__content {
  grid-column: 1;
  grid-row: 1;
}

.wp-block-media-text__media img,
.wp-block-media-text__media video {
  height: auto;
  max-width: unset;
  vertical-align: middle;
  width: 100%;
}

.wp-block-media-text.is-image-fill .wp-block-media-text__media {
  background-size: cover;
  height: 100%;
  min-height: 250px;
}

.wp-block-media-text.is-image-fill .wp-block-media-text__media > a {
  display: block;
  height: 100%;
}

.wp-block-media-text.is-image-fill .wp-block-media-text__media img {
  clip: rect(0, 0, 0, 0);
  border: 0;
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
@media (max-width: 600px) {
  .wp-block-media-text.is-stacked-on-mobile {
    grid-template-columns: 100% !important;
  }
  .wp-block-media-text.is-stacked-on-mobile .wp-block-media-text__media {
    grid-column: 1;
    grid-row: 1;
  }
  .wp-block-media-text.is-stacked-on-mobile .wp-block-media-text__content {
    grid-column: 1;
    grid-row: 2;
  }
}
.wp-block-columns {
  align-items: normal !important;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap !important;
}
@media (min-width: 782px) {
  .wp-block-columns {
    flex-wrap: nowrap !important;
  }
}
.wp-block-columns.are-vertically-aligned-top {
  align-items: flex-start;
}
.wp-block-columns.are-vertically-aligned-center {
  align-items: center;
}
.wp-block-columns.are-vertically-aligned-bottom {
  align-items: flex-end;
}
@media (max-width: 781px) {
  .wp-block-columns:not(.is-not-stacked-on-mobile) > .wp-block-column {
    flex-basis: 100% !important;
  }
}
@media (min-width: 782px) {
  .wp-block-columns:not(.is-not-stacked-on-mobile) > .wp-block-column {
    flex-basis: 0;
    flex-grow: 1;
  }
  .wp-block-columns:not(.is-not-stacked-on-mobile)
    > .wp-block-column[style*="flex-basis"] {
    flex-grow: 0;
  }
}
.wp-block-columns.is-not-stacked-on-mobile {
  flex-wrap: nowrap !important;
}
.wp-block-columns.is-not-stacked-on-mobile > .wp-block-column {
  flex-basis: 0;
  flex-grow: 1;
}
.wp-block-columns.is-not-stacked-on-mobile
  > .wp-block-column[style*="flex-basis"] {
  flex-grow: 0;
}

:where(.wp-block-columns) {
  margin-bottom: 1.75em;
}

:where(.wp-block-columns.has-background) {
  padding: 1.25em 2.375em;
}

.wp-block-column {
  flex-grow: 1;
  min-width: 0;
  overflow-wrap: break-word;
  word-break: break-word;
}
.wp-block-column.is-vertically-aligned-top {
  align-self: flex-start;
}
.wp-block-column.is-vertically-aligned-center {
  align-self: center;
}
.wp-block-column.is-vertically-aligned-bottom {
  align-self: flex-end;
}
.wp-block-column.is-vertically-aligned-stretch {
  align-self: stretch;
}
.wp-block-column.is-vertically-aligned-bottom,
.wp-block-column.is-vertically-aligned-center,
.wp-block-column.is-vertically-aligned-top {
  width: 100%;
}
.wp-block-group {
  box-sizing: border-box;
}

.wp-block-separator {
  border: 1px solid;
  border-left: none;
  border-right: none;
}
.wp-block-separator.is-style-dots {
  background: none !important;
  border: none;
  height: auto;
  line-height: 1;
  text-align: center;
}
.wp-block-separator.is-style-dots:before {
  color: currentColor;
  content: "···";
  font-family: serif;
  font-size: 1.5em;
  letter-spacing: 2em;
  padding-left: 2em;
}
