@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@layer base {
  html {
    -webkit-text-size-adjust: 100%;
  }

  body {
    @apply bg-primary text-secondary overflow-x-hidden;
  }
}

@layer components {
  .container {
    @apply max-w-container mx-auto px-5 from-tablet:px-8;
  }

  .aspect-h-1::before {
    content: "";
    display: block;
    padding-bottom: 100%;
  }

  .blog-article-content {
    @apply w-full;
    h2 {
      @apply text-xl from-desktop:text-2xl tracking-wider leading-snug mb-4 mt-6;
    }
    ul {
      @apply list-disc pl-6 flex flex-col gap-1;
      li {
        @apply tracking-wider leading-snug;
      }
    }
    p {
      @apply mb-4 tracking-wider leading-snug;
    }
    img {
      @apply inline my-3;
    }

    a {
      @apply underline font-bold hover:text-action transition-colors;
    }

    code {
      @apply w-full break-all inline-block;
    }
  }
}

@layer utilities {
  .simplebar-content-no-p .simplebar-content {
    padding: 0 !important;
  }
}

#nprogress {
  pointer-events: none;
}

#nprogress .bar {
  @apply bg-active;
  position: fixed;
  z-index: 1031;
  top: 0;
  left: 0;

  width: 100%;
  height: 2px;
}

#nprogress .peg {
  display: block;
  position: absolute;
  right: 0px;
  width: 100px;
  height: 100%;
  box-shadow: 0 0 10px #29d, 0 0 5px #29d;
  opacity: 1;

  -webkit-transform: rotate(3deg) translate(0px, -4px);
  -ms-transform: rotate(3deg) translate(0px, -4px);
  transform: rotate(3deg) translate(0px, -4px);
}

.lf-player-container {
  width: 100%;
  height: 100%;
}

.grecaptcha-badge {
  visibility: hidden;
}
